<template>
    <div class="bg-white p-5 rounded-lg">
        <h1 class="text-center text-xl my-5">註冊聊天室發送訊息內容</h1>
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item prop="content">
                <el-input v-model="form.content" type="textarea" rows="10"></el-input>
            </el-form-item>
        </el-form>
        <div class="my-10 flex justify-center">
            <el-button v-permission="['create', 'update']" class="mb-10" type="primary" native-type="submit" @click.prevent="onSubmit('form')">
                更新
            </el-button>
        </div>
    </div>
</template>

<script>
import firebase from "firebase/app";
import { firebaseConnectRef } from "@/plugins/firebase";
export default {
    data() {
        return {
            form: {
                content: "",
            },
            rules: {
                content: [
                    {
                        required: true,
                        message: "請輸入更新內容",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        // 取得註冊發送訊息資料
        async getData() {
            try {
                const data = await firebaseConnectRef("register_message").get();
                // 有資料在更新表單內容
                if (data.exists()) {
                    this.$set(this.form, "content", data.val().content);
                }
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得資料失敗",
                });
            }
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                await this.update();
                await this.getData();
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 更新註冊發送訊息資料
        async update() {
            try {
                await firebaseConnectRef("register_message").set({
                    ...this.form,
                    updatedAt: firebase.database.ServerValue.TIMESTAMP,
                });
                this.$message({
                    type: "success",
                    message: "更新成功",
                });
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "更新失敗",
                });
            }
        },
    },
    async created() {
        await this.getData();
    },
};
</script>
i
